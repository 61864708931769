<template>
  <div>
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <b-row>
              <b-col cols="2">
                <el-button
                  type="primary"
                  @click="add_department"
                  v-acl:DepartmentEdit.enable.show
                >{{
                  $t("Add")
                }}</el-button>
              </b-col>
              <b-col cols="10">
                <div v-if="!isSortableEnabled">
                  <el-button
                    type="primary"
                    @click="enableSort"
                    v-acl:DepartmentEdit.enable.show
                  >
                  {{ $t("Sort Order") }}
                  </el-button>
                </div>
                <div v-else>
                  <el-button
                    type="primary"
                    @click="disableSort"
                    v-acl:DepartmentEdit.enable.show
                  >
                    {{ $t("Save Change") }}
                  </el-button> &emsp;
                  Please drag and drop the table.
                </div>
              </b-col>
            </b-row>
            <p class="p-3" v-if="departmentlist">
              <el-table
                stripe
                :class="{handshape:isSortableEnabled}"
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="departmentlist"
                @row-click="row_clicked_event"
                ref="dragTable"
                row-key="IDn"
              >
                <el-table-column label="PLU" prop="PLU">
                </el-table-column>
                <el-table-column
                  :label="$t('Name') + '1'"
                  prop="Name1"
                >
                </el-table-column>
                <el-table-column
                  :label="$t('Name') + '2'"
                  prop="Name2"
                >
                </el-table-column>
                <el-table-column
                  :label="$t('Remarks')"
                  prop="Remarks"
                >
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row v-if="cur_department.IDn">
                  <b-col>
                    <p>ID: {{ cur_department.IDn }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Name') + '1'">
                      <b-input
                        placeholder="Name1"
                        class="form-control"
                        v-model="cur_department.Name1"
                        required
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Name') + '2'">
                      <b-input
                        placeholder="Name2"
                        class="form-control"
                        v-model="cur_department.Name2"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Remarks')">
                      <b-input
                        placeholder="Remarks"
                        class="form-control"
                        v-model="cur_department.Remarks"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col>
                    <b-form-file
                      v-model="image1"
                      :state="Boolean(image1)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-col>
                </b-row> -->

                <b-row class="mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center" v-if="cur_department.IDn">
                    <el-button
                      type="danger"
                      @click="confirmDialog=true"
                      v-acl:ProductsDelete.enable.show
                    >
                      <span>{{ $t("Delete") }}</span>
                    </el-button>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
      <el-dialog
        :title="$t('Please Confirm Delete')"
        :visible.sync="confirmDialog"
        width="30%"
        center>
        <h1 class="text-danger text-center">{{ $t('Deletion cannot be undone after you confirm') }}</h1>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmDialog = false" class="mr-6">{{ $t('Cancel') }}</el-button>
          <el-button type="primary" @click="deleteConfirmed" class="ml-6">{{ $t('Confirm') }}</el-button>
        </span>
      </el-dialog>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Row, Col, Dropdown, Dialog } from "element-ui";
import { getStore, setStore } from "../../util/helper";
import Sortable from "sortablejs";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dropdown.name]: Dropdown,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      currpage: "list",
      cur_department: null,
      cur_opt: null,
      new_department: {
        IDn: "",
        ID: "",
        PLU: "",
        Name1: "",
        Name2: "",
        SortOrder: "",
        BtnColor: "#cad1d7",
        Remarks: "",
        imagefile: "",
      },
      departmentlist: null,
      btnselectstyle: { backgroundColor: "#cad1d7" },

      image1: null,
      sortable: null,
      isSortableEnabled: false,

      dismessage: "",
      countdown: 0,
      confirmDialog: false,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    hasUtilityAuth,
    handleCommand(selected) {
      this.per_page = selected;
      return this.get_department_list();
    },
    disableSort() {
      this.sortable.option('disabled', true);
      this.isSortableEnabled = false;
      this.order_submit();
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    enableSort() {
      if (this.departmentlist.length <= 0) {
        return;
      }
      this.setSort();
      // this.sortable.option('disabled', false);
      this.isSortableEnabled = true;
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          // console.log("onEnd targetRow A:", evt.newIndex, evt.oldIndex, targetRow, this.departmentlist);
          const targetRow = this.departmentlist.splice(evt.oldIndex, 1)[0];
          this.departmentlist.splice(evt.newIndex, 0, targetRow);
          // console.log("onEnd targetRow B:", this.departmentlist);
        },
      });
    },
    add_department() {
      this.currpage = "edit";
      this.cur_department = this.new_department;
      this.btnselectchange();
      this.cur_opt = "add";
    },
    row_clicked_event(row, event, column) {
      // console.log("row_clicked_event", row, event, column);
      if (!hasUtilityAuth('DepartmentEdit')) return;
      this.currpage = "edit";
      this.cur_department = JSON.parse(JSON.stringify(row));
      this.btnselectchange();
      this.cur_opt = "update";
    },
    btnselectchange() {
      let color = this.cur_department.BtnColor;
      this.btnselectstyle = { backgroundColor: color };
    },
    form_cancel() {
      this.currpage = "list";
      // clear sort status
      if(this.isSortableEnabled){
        
        this.sortable.option('disabled', true);
        this.isSortableEnabled = false;
        // refresh list data
        this.get_department_list();
      }
    },
    async deleteConfirmed() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", this.cur_department.IDn);
      var that = this;
      this.confirmDialog = false
      
      axios({
        method: "post",
        url: "/Api/Web/department/delete",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 3;
              that.currpage = "list";
              that.get_department_list();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_department) {
        if (this.cur_department[key])
          bodyFormData.append(key, this.cur_department[key]);
      }
      bodyFormData.append("image1", this.image1);

      axios({
        method: "post",
        url: "/Api/Web/Department/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async order_submit() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var orderlist = [];
      for (var i = 0; i < this.departmentlist.length; i++){
        orderlist.push({IDn:this.departmentlist[i].IDn, SortOrder:i+1});
      }
      bodyFormData.append("SortOrderList", JSON.stringify(orderlist));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Department/sort_order",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.departmentlist = rt.data.departments;
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_department_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Department",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.departmentlist = rt.data.departments;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_department_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.handshape{
  cursor: pointer;
}
</style>
